<script>
import { mapGetters } from 'vuex';
import Heading from '~/features/overview/components/Heading';

import DisplayMixin from '@/mixins/DisplayMixin';
import OverviewWorkMode from '~/features/overview/components/OverviewWorkMode';
import TicketsManagerMixin from '~/features/tickets/mixins/TicketsManagerMixin';

const TICKETS_STATUS_OPEN = 1;

export default defineNuxtComponent({
  name: 'OverviewModule',
  setup() {
    const nuxt = useNuxtApp();
    const { __ } = nuxt.vueApp.config.globalProperties;

    const isSmallViewport = useMediaQuery('(max-width: 768px)');

    useHead({ title: __('Overview') });

    return { isSmallViewport };
  },
  components: {
    Heading,
    OverviewWorkMode,
  },
  mixins: [DisplayMixin, TicketsManagerMixin],
  data() {
    return {
      selectedOverviewTab: 0,
      overviewTabs: [
        {
          name: 'Tickets',
          detailedName: this.__('Open Tickets'),
          type: 'tickets',
          url: '/ticket/status/1',
          content: [],
        },
      ],
    };
  },
  async created() {
    this.setDefaultSortingParams(TICKETS_STATUS_OPEN);

    this.setSortingParams({
      ...this.sortingParams,
      'location_id[in]': this.selectedLocation.id,
    });
    await this.retrieveTickets();
    this.fetchTickets();
  },
  computed: {
    ...mapGetters('TicketsModuleStore', ['sortingParams', 'fetchedTickets']),
    ...mapGetters('LocationsStore', ['selectedLocation']),
  },
  watch: {
    fetchedTickets() {
      this.fetchTickets();
    },
    async selectedLocation() {
      this.setSortingParams({
        ...this.sortingParams,
        'location_id[in]': this.selectedLocation.id,
      });
      await this.retrieveTickets();
      this.fetchTickets();
    },
  },
  beforeMount() {
    this.listen();
  },
  beforeUnmount() {
    this.listen('off');
  },
  methods: {
    listen(status = 'on') {
      this.$eventBus[`$${status}`]('onTabClick', this.onTabChanged);
      this.$eventBus[`$${status}`]('onViewAll', this.onViewAll);
    },
    onViewAll() {
      const selectedTab = this.overviewTabs[this.selectedOverviewTab];
      if (selectedTab)
        this.$router.push(selectedTab.url);
    },
    onTabChanged(index) {
      this.selectedOverviewTab = index;
    },
    fetchTickets() {
      const ticketsTabIndex = this.overviewTabs.findIndex(
        tab => tab.type === 'tickets',
      );
      if (ticketsTabIndex !== -1) {
        const tabs = JSON.parse(JSON.stringify(this.overviewTabs));

        tabs[ticketsTabIndex] = {
          ...tabs[ticketsTabIndex],
          content: this.fetchedTickets.data.map(element => ({
            id: element.id.toString(),
            endDate: element.deadline_date,
            priority: this.ticketPriorityFormat(element.priority),
            comments: element.ticket_comment_count,
            group: element.group.name,
            title: element.title,
            category: element.ticket_category.name,
            user: element.user,
            data: element,
          })),
        };

        this.overviewTabs = tabs;
      }
    },

    ticketPriorityFormat(ticketPriority) {
      if (!ticketPriority)
        return '';

      switch (ticketPriority.id) {
        case 1: {
          return 'priorityLow';
        }
        case 2: {
          return 'priorityMedium';
        }
        case 3: {
          return 'priorityHigh';
        }
      }
    },
  },
});
</script>

<template>
  <Heading />

  <div class="flex size-full flex-col pb-6 md:p-0">
    <TfaBanner
      v-if="($auth.user.value && $auth.user.value.isIdentityProviderUser === false) && !isSmallViewport"
    />

    <div class="flex h-full flex-col">
      <OverviewWorkMode
        :device-type="getDisplayMode"
        :selected-tab="selectedOverviewTab"
        :tabs="overviewTabs"
        class="flex h-full"
      />
    </div>
  </div>
</template>
