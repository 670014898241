<script>
import { mapGetters } from 'vuex';

import TicketsManagerMixin from '~/features/tickets/mixins/TicketsManagerMixin';
import RegistrationsControllerMixin from '~/features/registrations/mixins/RegistrationsControllerMixin';

const deviceTypes = {
  mobile: defineAsyncComponent(() => import('./Mobile')),
  tablet: defineAsyncComponent(() => import('./Tablet')),
  desktop: defineAsyncComponent(() => import('./Desktop')),
  small_desktop: defineAsyncComponent(() => import('./SmallDesktop')),
};

export default defineNuxtComponent({
  name: 'OverviewWorkMode',
  mixins: [RegistrationsControllerMixin, TicketsManagerMixin],
  props: {
    tabs: {
      type: Array,
      default: () => [],
      required: false,
    },
    selectedTab: {
      type: Number,
      default: 0,
      required: false,
    },
    deviceType: {
      type: String,
      default: 'desktop',
      required: false,
    },
  },
  data() {
    return {
      overviewSquares: {
        announced: {
          name: this.__('Visitors announced'),
          value: 0,
          iconName: 'profile',
        },
        badges: {
          name: this.__('Badges'),
          value: 0,
          iconName: 'badge',
        },
        signedIn: {
          name: this.__('Visitors signed in'),
          value: 0,
          iconName: 'registration',
        },
        signedOut: {
          name: this.__('Visitors signed out'),
          value: 0,
          iconName: 'sign-out',
        },
      },
    };
  },
  async created() {
    const headers = useRequestHeaders(['cookie']);

    if (this.$auth.can('accessrequest-announcement-list')) {
      const filterDate = new Date();
      filterDate.setHours(0, 0, 0, 0);
      await $fetch('/api/v2/accessrequest/announcements', {
        params: {
          'per_page': 1000,
          'date_from[gte]': filterDate.toISOString(),
        },
        headers,
      })
        .then((result) => {
          let totalAnnounced = 0;
          result.data?.map(
            value =>
              (totalAnnounced += Number.parseInt(value?.counts?.persons) || 0),
          );
          this.overviewSquares.announced.value = totalAnnounced;
        })
        .catch((error) => {
          if (error.response.status === 401)
            this.logout();
        });
    }

    if (this.$auth.can('accessrequest-registration-list')) {
      await $fetch('/api/v2/accessrequest/registrations/signed-in', {
        params: {
          per_page: 1,
        },
        headers,
      })
        .then((result) => {
          const {
            meta: { total: totalSignedIn },
          } = result;
          this.overviewSquares.signedIn.value = totalSignedIn;
        })
        .catch((error) => {
          if (error.response.status === 401)
            this.logout();
        });

      await $fetch('/api/v2/accessrequest/registrations/signed-out', {
        params: {
          per_page: 1,
        },
        headers,
      })
        .then((result) => {
          const {
            meta: { total: totalSignedOut },
          } = result;
          this.overviewSquares.signedOut.value = totalSignedOut;
        })
        .catch((error) => {
          if (error.response.status === 401)
            this.logout();
        });

      const badges = await this.getLocationBadges(this.selectedLocation.id);

      if (badges.length > 0) {
        const totalBadges
          = badges?.[0]?.entities.in.length + badges?.[0]?.entities.out.length;
        this.overviewSquares.badges.value
          = `${badges?.[0]?.entities.out.length}/${totalBadges}`;
      }
    }
  },
  computed: {
    ...mapGetters('LocationsStore', ['selectedLocation']),
    deviceTypeComponent() {
      return deviceTypes[this.deviceType];
    },
  },
  mounted() {
    if (this.$route.query.id)
      this.openTicket(this.$route);
  },
  watch: {
    $route(newRoute) {
      if (this.$route.query.id)
        this.openTicket(newRoute);
    },
    async selectedLocation(value) {
      if (value.id) {
        const badges = await this.getLocationBadges(value.id);

        if (badges.length > 0) {
          const totalBadges
            = badges[0].entities.in.length + badges[0].entities.out.length;
          this.overviewSquares.badges.value
            = `${badges[0].entities.out.length}/${totalBadges}`;
        }
      }
    },
  },
  methods: {
    async openTicket(route) {
      const ticket = await this.retrieveTicketById(route.query.id);
      if (ticket)
        this.openTicketDetailsModal(ticket.data);
    },
    openTicketDetailsModal(ticket) {
      this.$store.commit('CreateTicketStore/clearTicketStore');
      this.setModalType('ticketDetailsModal');
      this.setModalData(ticket);
      this.setModalOpened(true);
    },
    async logout() {
      const { user, logout } = useAuth();
      if (user.value) {
        logout();
        await (user.value.identityProvider
        && user.value.identityProvider.logout_url
          ? navigateTo(user.value.identityProvider.logout_url, {
            external: true,
          })
          : navigateTo('/login'));
      }
    },
  },
});
</script>

<template>
  <div>
    <component
      :is="deviceTypeComponent"
      :overview-squares="overviewSquares"
      :selected-tab="selectedTab"
      :tabs="tabs"
    />
  </div>
</template>
