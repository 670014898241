export default {
  name: 'RegistrationsApiMixin',
  methods: {
    async getSignedOut() {
      return await $fetch(`/api/v2/accessrequest/registrations/signed-out`, {
        baseURL: this.$baseURL,
      });
    },
    async getSignedIn() {
      return await $fetch(`/api/v2/accessrequest/registrations/signed-in`, {
        baseURL: this.$baseURL,
      });
    },
    async getAnnounced() {
      return await $fetch(
        `/api/v2/accessrequest/announcements?embed=persons&sort=date_till&per_page=100`,

        { baseURL: this.$baseURL },
      );
    },
    async getAnnouncementPerson(id) {
      return await $fetch(`/api/v2/accessrequest/announcements/persons/${id}`, {
        baseURL: this.$baseURL,
      });
    },
    async getAnnouncementPersons() {
      return await $fetch(`/api/v2/accessrequest/announcements/persons`, {
        baseURL: this.$baseURL,
      });
    },
    async getVisitorTypes() {
      return await $fetch(`/api/v2/accessrequest/visitor-types`, {
        baseURL: this.$baseURL,
      });
    },
    async getGroupVisitors(groupId) {
      return await $fetch(`/api/v2/accessrequest/groups/${groupId}/members`, {
        baseURL: this.$baseURL,
      });
    },
    async getAuthorizations(groupId, type) {
      return await $fetch(
        `/api/v2/accessrequest/groups/${groupId}/authorizations/${type}`,
        { baseURL: this.$baseURL },
      );
    },
    async getInventory(locationId, inventory) {
      const headers = useRequestHeaders(['cookie']);

      return await $fetch(
        `/api/v2/accessrequest/inventories/location/${locationId}/${inventory}`,
        { baseURL: this.$baseURL, headers },
      );
    },
    async postAnnouncement(payload) {
      return await $fetch(`/api/v2/accessrequest/announcements`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: payload,
      });
    },
    async postRegistration(payload) {
      return await $fetch(`/api/v2/accessrequest/registrations`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: payload,
      });
    },
    async postSignOutRegistration(payload) {
      return await $fetch(`/api/v2/accessrequest/registrations/signout`, {
        method: 'POST',
        baseURL: this.$baseURL,

        body: payload,
      });
    },
  },
};
