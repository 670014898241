import { mapGetters } from 'vuex';

import RegistrationsApiMixin from './RegistrationsApiMixin';
import ModalControllerMixin from '@/mixins/ModalControllerMixin';
import ToastManagerMixin from '@/mixins/ToastManagerMixin';
import { toCamelCase } from '@/utilities/string-utilities';

export default {
  name: 'RegistrationsControllerMixin',
  mixins: [RegistrationsApiMixin, ModalControllerMixin, ToastManagerMixin],
  computed: {
    ...mapGetters('RegistrationStore', [
      'announcedVisitors',
      'visitorTypes',
      'signInPayload',
      'registrationPayload',
      'keys',
      'badges',
    ]),
  },
  methods: {
    toCamelCase,
    errorHandler(componentContext, errors) {
      const errorKeys = Object.keys(errors);
      const sanitizedError = [];
      for (const errorKey of errorKeys) {
        sanitizedError.push({
          key: this.toCamelCase(errorKey),
          error: errors[errorKey][0],
        });
      }

      for (const thisKey of Object.keys(componentContext.$data)) {
        const cleanThisErrorKey = thisKey.replace('Error', '');
        const errorFound = sanitizedError.find(
          errorKey => errorKey.key === cleanThisErrorKey,
        );
        const errorText = errorFound ? errorFound.error : '';

        if (
          componentContext.$data[thisKey] !== undefined
          && thisKey.includes('Error')
        )
          componentContext.$data[thisKey] = errorText;
      }
    },
    setAnnouncedVisitors(announcedVisitors) {
      this.$store.commit(
        'RegistrationStore/setAnnouncedVisitors',
        announcedVisitors,
      );
    },
    async receiveAnnouncedVisitors() {
      try {
        const response = await this.getAnnounced();

        // Filter older announcements
        return response.data.filter((announcement) => {
          return new Date(announcement.date_till) >= new Date();
        });
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to receive Announcements'),
          autoDismiss: 5000,
          callback: () => {},
        });
      }

      return [];
    },
    async receiveSignedOutVisitors() {
      try {
        const response = await this.getSignedOut();
        return response.data;
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to receive Signed out Visitors'),
          autoDismiss: 5000,
          callback: () => {},
        });
      }

      return [];
    },
    async receiveSignedInVisitors() {
      try {
        const response = await this.getSignedIn();
        return response.data;
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to receive Signed in Visitors'),
          autoDismiss: 5000,
          callback: () => {},
        });
      }

      return [];
    },
    async retrieveAnnouncementPersons() {
      try {
        const response = await this.getAnnouncementPersons();
        this.$store.commit(
          'RegistrationStore/setAnnouncementPersons',
          response.data,
        );
        return response.data;
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to receive Announcement Persons'),
          autoDismiss: 5000,
          callback: () => {},
        });
        return [];
      }
    },
    async fetchVisitorTypes() {
      if (this.visitorTypes.length === 0) {
        try {
          const response = await this.getVisitorTypes();
          this.$store.commit(
            'RegistrationStore/setVisitorTypes',
            response.data,
          );
          return response.data;
        }
        catch {
          this.$toasty.$error({
            title: this.__('Something went wrong'),
            message: this.__('Failed to receive Visitor Types'),
            autoDismiss: 5000,
            callback: () => {},
          });
        }
      }
      else {
        return this.visitorTypes;
      }
    },
    async signInAnnouncement() {
      try {
        const payload = this.registrationPayload;
        await this.postRegistration(payload).then((response) => {
          if (response.data) {
            this.setModalOpened(false);
            this.receiveAnnouncedVisitors();
            this.receiveSignedInVisitors();
          }
        });
      }
      catch (error) {
        if (error.response.data !== undefined) {
          this.$store.commit(
            'RegistrationStore/registrationErrorTrigger',
            error.response.data,
            { root: true },
          );
        }
      }
    },
    async createNewAnnouncement() {
      try {
        const payload = this.signInPayload;
        await this.postAnnouncement(payload).then((response) => {
          if (response.data) {
            this.setModalOpened(false);
            this.receiveAnnouncedVisitors();
          }
        });
      }
      catch (error) {
        if (error.response.data !== undefined) {
          this.$store.commit(
            'RegistrationStore/registrationErrorTrigger',
            error.response.data,
            { root: true },
          );
        }
        return false;
      }
      return true;
    },
    async getAuthorizationsForGroup(groupId, type) {
      try {
        const response = await this.getAuthorizations(groupId, type);
        return response.data;
      }
      catch {
        this.$toasty.$error({
          title: this.__('Something went wrong'),
          message: this.__('Failed to receive Authorizations'),
          autoDismiss: 5000,
          callback: () => {},
        });
        return [];
      }
    },
    getLocationKeys(locationId, callback) {
      if (this.keys.length === 0) {
        this.getInventory(locationId, 'keys').then((response) => {
          this.$store.commit('RegistrationStore/setKeys', response.data);

          if (callback)
            callback(this.keys);
        });
      }
      else {
        if (callback)
          callback(this.keys);

        return this.keys;
      }
    },
    async getLocationBadges(locationId) {
      try {
        const response = await this.getInventory(locationId, 'badges');
        return response.data;
      }
      catch {
        return [];
      }
    },
  },
};
